<template>
    <b-overlay :show="isLoading" rounded="sm">
        <div class="card col-12" style="background-color: #eef0f8;">
            <div class="card-title">
                <h4 class="mt-4 text-center">Actualizar mis datos</h4>
            </div>
            <div class="card-body">
                <div class="row">
                  <div class="col-2">
                    <b-form-group label="Rut" disabled = "disabled">
                      <v-text-field
                          v-model="personRut"
                      ></v-text-field>
                    </b-form-group>
                  </div>
                    <div class="col-2">
                        <b-form-group label="Nombre">
                            <v-text-field
                                    v-model="personName"
                            ></v-text-field>
                        </b-form-group>
                    </div>
                    <div class="col-3">
                        <b-form-group label="Apellidos">
                            <v-text-field
                                    v-model="personLastName"
                            ></v-text-field>
                        </b-form-group>
                    </div>
                    <div class="col-2">
                        <b-form-group label="Teléfono">
                            <v-text-field
                                    v-model="personPhone"
                            ></v-text-field>
                        </b-form-group>
                    </div>
                  <div class="col-3">
                    <b-form-group label="Correo Electrónico" disabled="disabled">
                      <v-text-field
                          v-model="personEmail"
                      ></v-text-field>
                    </b-form-group>
                  </div>
                </div>
            </div>
            <div class="card-footer" style="background-color: #eef0f8;">
                <div class="row">
                    <div class="col-md-12 text-right">
                        <b-button type="button" variant="primary" @click="updateInfo()">Actualizar</b-button>
                    </div>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<style>
    .lb-profile {
        font-weight: bold;
        font-size: larger;
    }

    .ipt-profile {
        border-color: #000000;
        background-color: #FFFFFF;
    }
</style>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import {UPDATE_PERSONAL_INFO} from "@/store/profile.module";
    import UserService, {getUserInfo} from "@/core/services/user.service";

    export default {
        name: "Profile",
        data() {
            return {
                isLoading: false,
                personName: "",
                personLastName: "",
                personPhone: "",
                personRut: "",
                personEmail: "",
                user: UserService.getUserInfo()
            };
        },
        components: {},
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: "Perfil"}]);
            this.personName = this.user.detail.name
            this.personLastName = this.user.detail.lastname
            this.personPhone = this.user.detail.phone
            this.personRut = this.user.detail.rut + "-" + this.user.detail.dv
            this.personEmail = this.user.email
        },
        methods: {
            updateInfo(){
                let scope = this
                scope.isLoading = true
                this.$store
                    .dispatch(UPDATE_PERSONAL_INFO, {
                        name: this.personName,
                        lastname: this.personLastName,
                        phone: this.personPhone,
                        rut: this.personRut,
                        email: this.personEmail,
                    })
                    .then((response) => {
                        scope.$bvToast.toast(
                            response.message,
                            {
                                title: "Mis datos",
                                variant: "success",
                                solid: true,
                            }
                        )
                        UserService.saveUserInfo(response.user)

                        scope.isLoading = false
                    }).catch(() => {
                    scope.$bvToast.toast(
                        "El servidor devolvio un error",
                        {
                            title: "Mis datos",
                            variant: "danger",
                            solid: true,
                        }
                    );
                    scope.isLoading = false
                });
            }
        },
    };
</script>

<style scoped></style>
